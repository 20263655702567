
import Vue from 'vue'
import SiteLayoutDefault from '~/components/site/layout/Default.vue'

export default Vue.extend({
  name: 'SiteLayoutWithSideContent',
  components: { SiteLayoutDefault },
  props: {
    leftSideContent: {
      type: Boolean,
    },
    bottomPadding: {
      type: Boolean,
      default: true,
    },
    topPadding: {
      type: Boolean,
      default: true,
    },
    extraTopPadding: {
      type: Boolean,
    },
    collapseSideUp: {
      type: Boolean,
    },
    collapsedGap: {
      type: Boolean,
      default: true,
    },
    showSideOnMobile: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    gutters(): string {
      let topGutter = ''
      if (this.collapsedGap) {
        topGutter = this.collapseSideUp ? '1col:mb-6' : '1col:mt-20'
      }
      return `${topGutter} ${
        this.leftSideContent ? '2col:mr-16' : '2col:ml-20'
      } ${this.showSideOnMobile ? 'block' : 'hidden 2col:block'}`
    },
    sideOrder(): string {
      if (this.collapseSideUp && !this.leftSideContent) {
        return '1col:order-first'
      } else if (this.leftSideContent && !this.collapseSideUp) {
        return '2col:order-first'
      } else if (this.leftSideContent && this.collapseSideUp) {
        return 'order-first'
      }
      return ''
    },
  },
})
