
import Vue, { PropType } from 'vue'
import { TranslateResult } from 'vue-i18n'
import Poller from '~/components/_general/Poller.vue'
import { clamp } from '~/assets/ts/utils/math'

interface SeparatedTime {
  days: string
  hours: string
  minutes: string
  seconds: string
}

export default Vue.extend({
  name: 'Countdown',
  components: { Poller },
  props: {
    start: {
      type: Number,
      required: true,
    },
    end: {
      type: Number,
      default: 0,
    },
    endedText: {
      type: String as PropType<TranslateResult>,
      default: '',
    },
    inProgressText: {
      type: String as PropType<TranslateResult>,
      default: '',
    },
    countDuration: {
      type: Number,
      default: 0.3, // seconds
    },
    enableReset: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      setup: false,
      msRemaining: undefined as number | undefined,
      countMs: 0,
      timeout: 0,
    }
  },
  computed: {
    separatedTimeRemaining(): SeparatedTime {
      if (!this.msRemaining || !this.countMs) {
        const placeholder = '00'
        return {
          days: placeholder,
          hours: placeholder,
          minutes: placeholder,
          seconds: placeholder,
        }
      }

      const seconds = Math.round(
        (this.setup ? this.msRemaining : this.countMs) / 1000
      )
      const days = Math.floor(seconds / (24 * 60 * 60))
      const hours = Math.floor(seconds / 3600) - days * 24
      const min = Math.floor((seconds % 3600) / 60)
      const sec = seconds % 60

      return {
        days: this.formatNumber(days),
        hours: this.formatNumber(hours),
        minutes: this.formatNumber(min),
        seconds: this.formatNumber(sec),
      }
    },
    duration(): number {
      return this.end - this.start
    },
    ended(): boolean {
      if (!this.msRemaining || !this.started || !this.end) return false
      return this.msRemaining * -1 > this.duration
    },
    started(): boolean {
      return this.msRemaining !== undefined && this.msRemaining <= 0
    },
  },
  mounted() {
    this.updateTime()
  },
  destroyed() {
    clearTimeout(this.timeout)
  },
  methods: {
    formatNumber(n: number): string {
      return `${n > 9 ? '' : '0'}${Math.max(0, n)}`
    },
    updateTime() {
      this.msRemaining = this.start - new Date().getTime()
      this.$emit('remaining', this.msRemaining)
      if (this.countMs >= this.msRemaining) {
        this.setup = true
      } else {
        const animationUpdates = this.countDuration / 0.00833
        const step = this.msRemaining / animationUpdates
        this.countMs = clamp(this.countMs + step, 0, this.msRemaining)
        this.timeout = window.setTimeout(() => {
          this.updateTime()
        }, (this.countDuration * 1000) / animationUpdates)
      }
    },
  },
})
