
import Vue from 'vue'
import { pojoToSermon, Sermon } from '~/models/sermon'
import { SermonDisplay } from '~/assets/ts/enums'
import SiteLayoutWithSideContent from '~/components/site/layout/WithSideContent.vue'
import SiteTitledSection from '~/components/site/TitledSection.vue'
import SiteFeaturedSpotlightContent from '~/components/site/featured/SpotlightContent.vue'
import SermonElement from '~/components/_general/SermonElement.vue'
import SiteTextFeatureElement from '~/components/site/TextFeatureElement.vue'
import SiteFeaturedCopyrightElement from '~/components/site/featured/CopyrightElement.vue'
import { AdType, DisplayAdApi } from '~/apiclient/apiads'
import HorizontalRule from '~/components/_general/HorizontalRule.vue'
import { GetAdTypeApi } from '~/assets/ts/utils/promotions'
import { firstInArray } from '~/assets/ts/utils/lists'

export default Vue.extend({
  name: 'SiteLayoutWithPromo',
  components: {
    HorizontalRule,
    SiteFeaturedCopyrightElement,
    SiteTextFeatureElement,
    SermonElement,
    SiteFeaturedSpotlightContent,
    SiteTitledSection,
    SiteLayoutWithSideContent,
  },
  props: {
    includeSponsors: {
      type: Boolean,
      default: true,
    },
    includeFeaturedSermon: {
      type: Boolean,
      default: true,
    },
    includeMobileFeatures: {
      type: Boolean,
      default: true,
    },
    /** to disable this feature, use a value of 0 */
    maxSponsors: {
      type: Number,
      default: 3,
    },
  },
  computed: {
    featuredSermonDisplay(): SermonDisplay {
      return SermonDisplay.Featured
    },
    renderFeatured(): boolean {
      return this.includeFeaturedSermon && !!this.featuredSermon
    },
    renderSponsors(): boolean {
      return this.includeSponsors && !!this.graphicAds.length
    },
    renderTextAds(): boolean {
      return !!this.textAds.length
    },
    featuredSermon(): Sermon | undefined {
      return pojoToSermon(this.getAd(AdType.FeaturedSermon)?.sermon)
    },
    displayAds(): DisplayAdApi[] {
      return this.$store.getters['site/displayAds']
    },
    textAds(): DisplayAdApi[] {
      return this.getAdList(AdType.Text)
    },
    graphicAds(): DisplayAdApi[] {
      const sponsors = this.getAdList(AdType.Graphic)
      if (!this.maxSponsors) return sponsors
      return [...sponsors].slice(0, this.maxSponsors)
    },
    spotlightFeatures(): DisplayAdApi[] {
      return this.getAdList(AdType.Spotlight)
    },
  },
  async mounted() {
    await this.$store.dispatch('site/fetchDisplayAds')
  },
  methods: {
    getAdList(adType: AdType): DisplayAdApi[] {
      const types = [GetAdTypeApi(adType, true), GetAdTypeApi(adType, false)]
      return this.displayAds.filter((a) => types.includes(a.adType))
    },
    getAd(adType: AdType): DisplayAdApi | undefined {
      return firstInArray(this.getAdList(adType))
    },
  },
})
